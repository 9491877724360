import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
export const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD
export const Home = Loadable(lazy(() => import('../pages/dashboard/Home')));
export const Events = Loadable(lazy(() => import('../pages/dashboard/Events')));
export const SettingsTags = Loadable(lazy(() => import('../pages/dashboard/settings/Tags')));
export const SettingsGeneral = Loadable(lazy(() => import('../pages/dashboard/settings/General')));

export const LoginActivities = Loadable(lazy(() => import('../pages/auth/LoginActivities')));
export const EditTeam = Loadable(lazy(() => import('../pages/dashboard/user/EditTeam')));
export const EditUser = Loadable(lazy(() => import('../pages/dashboard/user/EditUser')));
export const UserProfile = Loadable(lazy(() => import('../pages/auth/Profile')));
export const UserList = Loadable(lazy(() => import('../pages/dashboard/user/UserList')));
export const NewUser = Loadable(lazy(() => import('../pages/dashboard/user/NewUser')));
export const Teams = Loadable(lazy(() => import('../pages/dashboard/user/Teams')));
export const NewTeam = Loadable(lazy(() => import('../pages/dashboard/user/NewTeam')));

export const BallotBoxList = Loadable(lazy(() => import('../pages/dashboard/ballot-box/BallotBoxList')));
export const NewBallotBox = Loadable(lazy(() => import('../pages/dashboard/ballot-box/NewBallotBox')));
export const EditBallotBox = Loadable(lazy(() => import('../pages/dashboard/ballot-box/EditBallotBox')));

export const GeneralReports = Loadable(lazy(() => import('../pages/dashboard/reports/GeneralReports')));
export const UserReports = Loadable(lazy(() => import('../pages/dashboard/reports/UserReports')));
export const TeamReports = Loadable(lazy(() => import('../pages/dashboard/reports/TeamReports')));

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(lazy(() => import('../pages/dashboard/PermissionDeniedPage')));

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
