import ReactQuill from 'react-quill';
//
import { EditorProps } from './types';
import { StyledEditor } from './styles';
import EditorToolbar, { formats } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';

// ----------------------------------------------------------------------
interface IEditorProps extends EditorProps {
  placeholder?: string;
}

export default function Editor({
  placeholder,
  id = 'minimal-quill',
  error,
  value,
  simple = false,
  helperText,
  sx,
  onChange,
  ...other
}: IEditorProps) {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: false,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: theme => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />

        <ReactQuill value={value} onChange={onChange} modules={modules} formats={formats} placeholder={placeholder} {...other} />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
