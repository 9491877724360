export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

export default function mergeObject(target: any, source: any) {
  const result = { ...target, ...source };
  const keys = Object.keys(result);

  for (const key of keys) {
    const tprop = target[key];
    const sprop = source[key];
    if (typeof tprop == 'object' && typeof sprop == 'object') {
      result[key] = mergeObject(tprop, sprop);
    }
  }

  return result;
}
