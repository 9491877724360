// @mui
import { Typography, Stack, StackProps, SxProps } from '@mui/material';
//
import Image from '../image';

// ----------------------------------------------------------------------

interface EmptyContentProps extends StackProps {
  title?: string;
  img?: string;
  description?: string;
  sxImage?: SxProps;
}

export default function EmptyContent({ title = 'No data', description, img, sxImage = {}, sx, ...other }: EmptyContentProps) {
  return (
    <Stack
      alignItems='center'
      justifyContent='center'
      sx={{
        height: 1,
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
        p: theme => theme.spacing(8, 2),
        ...sx,
      }}
      {...other}
    >
      <Image
        disabledEffect
        alt='empty content'
        src={img || '/assets/illustrations/illustration_empty_content.svg'}
        sx={{ height: 240, mb: 3, ...sxImage }}
      />

      <Typography variant='h5' gutterBottom>
        {title}
      </Typography>

      {description && (
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
