//
import { HOST_DOCUMENT_KEY } from 'config-global';
import Image from '../../image';

// ----------------------------------------------------------------------

type Props = {
  file: string | null;
};

export default function AvatarPreview({ file }: Props) {
  if (!file) {
    return null;
  }

  return (
    <Image
      alt='avatar'
      src={HOST_DOCUMENT_KEY(file)}
      sx={{
        zIndex: 8,
        overflow: 'hidden',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
      }}
    />
  );
}
