// form
import { Controller, Control } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import { DatePickerProps } from '@mui/lab';
import { Dayjs } from 'dayjs';

// ----------------------------------------------------------------------

type IProps = DatePickerProps<Dayjs> & {
  name: string;
  control: Control<any>;
  label?: string;
  helperText?: React.ReactNode;
  fullWidth?: boolean;
};

export default function RHFDatePicker({ name, control, label, helperText, fullWidth = false, ...other }: IProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          label={label}
          slotProps={{
            textField: {
              fullWidth: fullWidth,
              variant: 'outlined',
              error: !!error,
              helperText: error?.message,
            },
          }}
          {...other}
        />
      )}
    />
  );
}
