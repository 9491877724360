// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
//
import useLocales from './useLocales';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  const outerTheme = useTheme();

  const { currentLang } = useLocales();

  // Theme locale
  const theme = createTheme(outerTheme, [currentLang.systemValue, currentLang.datePickerValue]);

  // Dayjs locale
  import(`dayjs/locale/${currentLang.value}.js`).then(() => dayjs.locale(currentLang.value));

  return (
    <LocalizationProvider
      adapterLocale={currentLang.value}
      localeText={currentLang.datePickerValue.components.MuiLocalizationProvider.defaultProps.localeText}
      dateAdapter={AdapterDayjs}
    >
      <ThemeProvider theme={theme}> {children} </ThemeProvider>
    </LocalizationProvider>
  );
}
