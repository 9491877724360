import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
// config
import { HOST_API_KEY } from '../config-global';
import QueryBuilder from './queryBuilder';
import { CustomAxiosInstance, IDynamicResponse } from 'types/dynamic-data';
import dayjs from 'dayjs';
const ISO_STRING_DATE_FORMAT = 'yyyy-MM-ddTHH:mm:ss.SSSZ';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY }) as CustomAxiosInstance;

axiosInstance.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

function parseDates(data: any) {
  if (typeof data === 'object') {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (typeof value === 'string' && dayjs(value, ISO_STRING_DATE_FORMAT).isValid()) {
          data[key] = dayjs(value);
        } else if (typeof value === 'object') {
          parseDates(value);
        }
      }
    }
  }
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    parseDates(response.data);
    return response;
  },
  error => {
    return Promise.reject(error.message);
  }
);

axiosInstance.getDynamicData = <T>(
  url: string,
  query: QueryBuilder,
  pageStart: number = 0,
  pageSize: number = 8,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<IDynamicResponse<T>>> => {
  return axiosInstance.post<any, AxiosResponse<IDynamicResponse<T>>>(
    `${url}?Page=${pageStart}&PageSize=${pageSize}`,
    query.result(),
    config
  );
};

export default axiosInstance;
