import { forwardRef } from 'react';
import { Box, Stack, Typography } from '@mui/material';
//
import { AvatarDetailedProps } from './types';
import CustomAvatar from './CustomAvatar';

const AvatarDetailed = forwardRef<HTMLDivElement, AvatarDetailedProps>(
  ({ color, name, secondary, src, BadgeProps, children, sx, ...other }, ref) => (
    <Stack direction='row' alignItems='center'>
      <CustomAvatar name={name} src={src} ref={ref} sx={sx} {...other} />

      <Box sx={{ ml: 2 }}>
        <Typography variant='subtitle2'>{name}</Typography>
        {!!secondary && (
          <>
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              {secondary}
            </Typography>
          </>
        )}
      </Box>
    </Stack>
  )
);

export default AvatarDetailed;
