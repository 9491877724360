import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Badge, Avatar } from '@mui/material';
//
import { HOST_API_KEY } from 'config-global';
import { CustomAvatarProps } from './types';

// ----------------------------------------------------------------------

const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();

const getColorByName = (name: string) => {
  if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary';
  if (['F', 'G', 'Ğ', 'T', 'I', 'İ', 'J'].includes(getCharAtName(name))) return 'info';
  if (['K', 'D', 'C', 'Ç', 'Y', 'B', 'O', 'Ö'].includes(getCharAtName(name))) return 'success';
  if (['P', 'E', 'R', 'S', 'Ş', 'U', 'Ü'].includes(getCharAtName(name))) return 'warning';
  if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error';
  return 'default';
};

// ----------------------------------------------------------------------

const CustomAvatar = forwardRef<HTMLDivElement, CustomAvatarProps>(
  ({ color, name = '', code, src, BadgeProps, children, sx, ...other }, ref) => {
    const theme = useTheme();

    const remoteSrc = typeof src == 'string' ? `${HOST_API_KEY}/api/files/${src}` : src;

    const charAtName = getCharAtName(code ? code : name);

    const colorByName = remoteSrc ? 'default' : getColorByName(code ? code : name);

    const myColor = color || colorByName;

    const renderContent =
      myColor === 'default' ? (
        <Avatar ref={ref} sx={sx} {...other} src={remoteSrc}>
          {name && code ? code : charAtName}
          {children}
        </Avatar>
      ) : (
        <Avatar
          ref={ref}
          sx={{
            color: theme.palette[myColor]?.contrastText,
            backgroundColor: theme.palette[myColor]?.main,
            fontWeight: theme.typography.fontWeightMedium,
            ...sx,
          }}
          {...other}
          src={remoteSrc}
        >
          {name && code ? code : charAtName}
          {children}
        </Avatar>
      );

    return BadgeProps ? (
      <Badge overlap='circular' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} {...BadgeProps}>
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);

export default CustomAvatar;
