import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, DialogTitle, DialogContent, DialogActions, Dialog, Button, Typography, Backdrop, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import MenuItem from '@mui/material/MenuItem';
import FormProvider, { RHFDatePicker, RHFRemoteSelect, RHFSelect, RHFTextField } from 'components/hook-form';
import axiosInstance from 'utils/axios';
import { CreatedEventDto } from 'features/events/types/create-event';
import { EventStatuses } from 'features/events/types/event';
import RHFEditor from 'components/hook-form/RHFEditor';
import { AvatarDetailed } from 'components/custom-avatar';
import Label from 'components/label';
import { useState } from 'react';
import { FULL_WIDTH } from 'theme/palette';
import { useEventContext } from 'features/events/hooks/EventContext';
// ----------------------------------------------------------------------
const DIALOG_WIDTH = 640;
const EVENT_STATUSES = EventStatuses;
const FormSchema = Yup.object().shape({
  teamId: Yup.mixed().nullable(),
  coordinatorId: Yup.mixed()
    .nullable()
    .when('teamId', {
      is: (teamId: any) => !teamId,
      then: (schema: Yup.AnySchema) => schema.required('Ekip veya koordinatörden bir tanesini seçin!'),
      otherwise: (schema: Yup.AnySchema) => schema.nullable(),
    }),
  typeId: Yup.number().required('Tip girilmesi zorunludur!').moreThan(0, 'Tip girilmesi zorunludur!'),
  statusId: Yup.string().required('Durum girilmesi zorunludur!'),
  startingDate: Yup.date()
    .required('Başlama tarihi girilmesi zorunludur!')
    .max(Yup.ref('endDate'), 'Başlama tarihi bitiş tarihinden sonrası olamaz!'),
  endDate: Yup.date().required('Bitiş tarihi girilmesi zorunludur!').min(Yup.ref('startingDate'), 'Bitiş tarihi başlangıçtan önce olamaz1'),
  name: Yup.string()
    .required('Etkinlik adı girilmesi zorunludur!')
    .min(6, 'En az 6 karakter girilmelidir.')
    .max(64, 'En fazla 64 karakter girilebilir.'),
});

type Props = {
  open: boolean;
  onSaved?: (id: number) => VoidFunction | void;
  onClosed?: VoidFunction;
};
const defaultCoordinatorUrl = '/api/users/avatars';

export default function CreateEventDialog({ open, onSaved, onClosed }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { onChanged } = useEventContext();

  const [coordinatorUrl, setCoordinatorUrl] = useState<string>(defaultCoordinatorUrl);
  const defaultValues = new CreatedEventDto();
  const methods = useForm<CreatedEventDto>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    resetField,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: CreatedEventDto) =>
    await axiosInstance
      .post('/api/events', data)
      .then(res => {
        if (res.status == 201) {
          enqueueSnackbar('Etkinlik başarıyla oluşturuldu!', { variant: 'success' });
          onSaved && onSaved(res?.data?.id as number);
          onChanged && onChanged(res?.data?.id);
          onClosed && onClosed();
          reset();
        }
      })
      .catch(error => {
        enqueueSnackbar('Error! ' + error?.title, { variant: 'error' });
      });

  return (
    <>
      {isSubmitting && (
        <Backdrop open sx={{ zIndex: theme => theme.zIndex.modal + 1 }}>
          <CircularProgress color='primary' />
        </Backdrop>
      )}
      <Dialog open={open} onClose={onClosed} maxWidth={'lg'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Yeni Etkinlik Oluştur</DialogTitle>
          <DialogContent sx={{ width: DIALOG_WIDTH, maxWidth: FULL_WIDTH }}>
            <Stack spacing={3} sx={{ py: 1 }}>
              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <RHFRemoteSelect
                  name='typeId'
                  label='Etkinlik Tipi'
                  searchPlaceholder='Ara...'
                  pageSize={8}
                  filterKeys={['name']}
                  url='/api/event-types/list-by-dynamic'
                  renderOption={opt => opt.name}
                />
                <RHFSelect name='statusId' label='Durum'>
                  {EVENT_STATUSES.map(option => (
                    <MenuItem color={option.color} key={option.id} value={option.id}>
                      <Label color={option.color}>{option.name}</Label>
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <RHFRemoteSelect
                name='teamId'
                label='Ekip'
                searchPlaceholder='Ara...'
                filterKeys={['name', 'code']}
                url='/api/teams/list-by-dynamic'
                onChange={(e: any) => {
                  setValue('teamId', e?.target?.value);
                  if (e?.target?.value) {
                    resetField('coordinatorId', { keepDirty: true, keepTouched: true, defaultValue: null });
                    setCoordinatorUrl(`/api/teams/${e?.target?.value}/members`);
                  } else {
                    setCoordinatorUrl(defaultCoordinatorUrl);
                  }
                }}
                renderOption={(opt: any) => <AvatarDetailed alt={opt.name} name={opt.name} code={opt.code} />}
              />
              <RHFRemoteSelect
                name='coordinatorId'
                label='Koordinatör'
                searchPlaceholder='Ara...'
                filterKeys={['fullName', 'email']}
                url={coordinatorUrl}
                renderOption={(opt: any) => (
                  <AvatarDetailed alt={opt?.fullName} name={opt?.fullName} secondary={opt?.email} src={opt.photo} />
                )}
              />
              <RHFTextField name='name' label='Etkinlik Adı' />
              <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                <RHFDatePicker control={control} fullWidth={true} name='startingDate' label='Başlama Zamanı' />
                <RHFDatePicker control={control} fullWidth={true} name='endDate' label='Bitiş Zamanı' />
              </Stack>
              <Stack spacing={1}>
                <Typography variant='subtitle2' sx={{ color: 'text.secondary' }}>
                  Açıklama
                </Typography>
                <RHFEditor
                  simple
                  style={{ height: '250px' }}
                  name='description'
                  placeholder='Etkinliği detaylandıran bir açıklama girebilirsiniz...'
                />
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClosed} color='inherit'>
              İptal
            </Button>
            <LoadingButton type='submit' variant='contained' loading={isSubmitting}>
              Etkinlik Oluştur
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
}
