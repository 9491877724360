import { LabelColor } from 'components/label';

export enum EventStatus {
  Draft = 'Draft',
  Assigned = 'Assigned',
  Continues = 'Continues',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export const EventStatuses = [
  {
    id: EventStatus.Draft,
    name: 'Taslak',
    color: 'default' as LabelColor,
  },
  {
    id: EventStatus.Assigned,
    name: 'Atandı',
    color: 'info' as LabelColor,
  },
  {
    id: EventStatus.Continues,
    name: 'Devam Ediyor',
    color: 'warning' as LabelColor,
  },
  {
    id: EventStatus.Completed,
    name: 'Tamamlandı',
    color: 'success' as LabelColor,
  },
  {
    id: EventStatus.Cancelled,
    name: 'İptal',
    color: 'error' as LabelColor,
  },
];

export const EventStatusById = (id: EventStatus | string) => EventStatuses.find(a => a.id == id);

export type IEvent = {
  id: number;
  coordinatorId: number;
  statusId: EventStatus;
  typeId: number;
  startingDate: Date;
  endDate: Date;
  participantCount: number;
  name: string;
  description: string;
  result: string;
};

export type IEventTags = {
  id: number;
  eventId: number;
  tagId: number;
};

export type IEventParticipants = {
  id: number;
  eventId: number;
  userId: number;
};

export type IEventFiles = {
  id: number;
  files: string[];
};
