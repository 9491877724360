export type QueryDir = 'asc' | 'desc';

export type QueryOperator =
  | 'eq' //: Eşit
  | 'neq' //: Eşit değil
  | 'lt' //: Küçüktür
  | 'lte' //: Küçüktür veya eşittir
  | 'gt' //: Büyüktür
  | 'gte' //: Büyüktür veya eşittir
  | 'isnull' //: null’dır
  | 'isnotnull' //: null değildir
  | 'startswith' //: İle başlar
  | 'endswith' //: İle biter
  | 'contains' //: İçerir
  | 'doesnotcontain'; //: İçermez;

export type QueryLogic = 'and' | 'or';

export interface QuerySort {
  field: string;
  dir: QueryDir;
}

export interface QueryFilter {
  field: string;
  operator: QueryOperator;
  value: string;
  logic?: QueryLogic;
  filters?: QueryFilter[];
}

export interface QueryBody {
  sort: QuerySort[];
  filter?: QueryFilter | null;
}

export default class QueryBuilder {
  private andQueries: QueryFilter[] = [];
  private orQueries: QueryFilter[] = [];
  private queryResult: QueryBody = { sort: [], filter: null };

  constructor(sort?: QuerySort[], filter?: QueryFilter) {
    if (sort) {
      this.queryResult.sort = sort;
    }

    if (filter) {
      this.andQueries = [filter];
    }
  }

  public sortNative = (sort: QuerySort) => {
    this.queryResult.sort = [...this.queryResult.sort, sort];
    return this;
  };

  public sort = (field: string, dir: QueryDir) => {
    this.queryResult.sort = [...this.queryResult.sort, { field, dir }];
    return this;
  };

  public andNative = (filter: QueryFilter) => {
    this.andQueries = [...this.andQueries, filter];
    return this;
  };

  public and = (field: string, operator: QueryOperator, value: string): this => {
    this.andQueries = [...this.andQueries, { field, operator, value }];
    return this;
  };

  public orNative = (filter: QueryFilter): this => {
    this.orQueries = [...this.orQueries, filter];
    return this;
  };

  public or = (field: string, operator: QueryOperator, value: string): this => {
    this.orQueries = [...this.orQueries, { field, operator, value }];
    return this;
  };

  public result() {
    if (this.andQueries.length) {
      for (const e of this.andQueries) {
        if (!this.queryResult.filter) {
          if (this.orQueries.length) {
            e.logic = 'or';
            e.filters = this.orQueries;
          }
          this.queryResult.filter = e;
        } else {
          e.logic = 'and';
          e.filters = [this.queryResult.filter];
          this.queryResult.filter = e;
        }
      }
    } else if (this.orQueries.length) {
      const [first, ...rest] = this.orQueries;
      first.logic = 'or';
      first.filters = rest;
      this.queryResult.filter = first;
    }

    return this.queryResult;
  }
}
