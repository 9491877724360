import dayjs, { Dayjs } from 'dayjs';
import { EventStatus } from './event';

export class CreatedEventDto {
  teamId: number | null = null;
  coordinatorId: number | null = null;
  typeId: number | null = null;
  statusId: EventStatus = EventStatus.Draft;
  startingDate: Dayjs = dayjs(new Date());
  endDate: Dayjs = dayjs(new Date()).add(1, 'day');
  name: string | null = '';
  description: string | null = null;
}
