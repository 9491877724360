//
import { HOST_DOCUMENT_KEY } from 'config-global';
import Image from '../../image';

// ----------------------------------------------------------------------

type Props = {
  file: any;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  return (
    <Image
      alt='file preview'
      src={HOST_DOCUMENT_KEY(file)}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
