import { Stack, Typography } from '@mui/material';
import { AvatarPickerProps } from './types';
import React from 'react';
import { allAvatars } from './constant';

export default function AvatarPicker({ helpText, onChange }: AvatarPickerProps) {
  return (
    <>
      <Typography
        variant='caption'
        sx={{
          mt: 2,
          mx: 'auto',
          display: 'block',
          textAlign: 'center',
          color: 'text.secondary',
          marginY: '2rem',
        }}
      >
        {helpText ? helpText : 'Aşağıda ki avatarlardan birisini seçebilir veya yeni bir fotoğraf yükleyebilirsin.'}
      </Typography>
      <Stack sx={{ display: 'flex', mt: 4, justifyContent: 'center', alignItems: 'center' }} flexWrap='wrap' gap={2} direction='row'>
        {allAvatars.map((avatar: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <img
                onClick={(e: any) => (onChange ? onChange(avatar.name) : null)}
                style={{ width: '30px', height: '30px', borderRadius: '50%', cursor: 'pointer' }}
                src={avatar.src}
                alt={avatar.name}
              />
            </React.Fragment>
          );
        })}
      </Stack>
    </>
  );
}
