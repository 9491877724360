import { useDropzone } from 'react-dropzone';
// @mui
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
//
import Iconify from '../iconify';
import Camera from 'assets/help_desk/camera.svg';

//
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import AvatarPreview from './preview/AvatarPreview';
import React from 'react';
import { AvatarPicker } from 'components/avatar-picker';
import useFileUpload from './hooks/useFileUpload';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  display: 'flex',
  cursor: 'pointer',
  overflow: 'hidden',
  borderRadius: '50%',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
}));

const StyledPlaceholder = styled('div')(({ theme }) => ({
  zIndex: 7,
  display: 'flex',
  borderRadius: '50%',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: `calc(100% - 16px)`,
  height: `calc(100% - 16px)`,
  color: theme.palette.text.disabled,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

export default function UploadAvatar({
  error,
  file,
  disabled,
  enableAvatarPicker = false,
  enableCustomButton = false,
  editPlaceholderText = 'Fotoğrafı Güncelle',
  addPlaceholderText = 'Fotoğraf Yükle',
  uploadButtonText = 'Fotoğraf Yükle',
  helperText,
  sx,
  onChange,
  ...other
}: UploadProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const completed = (values: string[]) => {
    if (values.length && onChange) {
      onChange(values[0]);
    }
  };

  const { started, progress, startUpload } = useFileUpload(completed);
  const remoteDrop = async (files: File[]) => startUpload(files);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    disabled,
    onDrop: remoteDrop,
    ...other,
  });

  const hasFile = !!file;

  const isError = isDragReject || !!error;

  return (
    <>
      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            borderColor: 'error.light',
            ...(hasFile && {
              bgcolor: 'error.lighter',
            }),
          }),
          ...(disabled && {
            pointerEvents: 'none',
          }),
          ...(hasFile && {
            '&:hover': {
              '& .placeholder': {
                opacity: 1,
              },
            },
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} ref={fileInputRef} />

        {started && <CircularProgress key='file-upload-progress' variant='determinate' value={progress} color='primary' size={50} />}
        {hasFile && !started && <AvatarPreview file={file} />}

        {!started && (
          <StyledPlaceholder
            className='placeholder'
            sx={{
              '&:hover': {
                opacity: 0.72,
              },
              ...(hasFile && {
                zIndex: 9,
                opacity: 0,
                color: 'common.white',
                bgcolor: theme => alpha(theme.palette.grey[900], 0.64),
              }),
              ...(isError && {
                color: 'error.main',
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify icon='ic:round-add-a-photo' width={24} sx={{ mb: 1 }} />
            <Typography variant='caption'>{file ? editPlaceholderText : addPlaceholderText}</Typography>
          </StyledPlaceholder>
        )}
      </StyledDropZone>

      {enableCustomButton && (
        <Stack sx={{ display: 'flex', justifyContent: 'center', pt: 3, alignItems: 'center' }} flexWrap='wrap' gap={2} direction='row'>
          <Button
            onClick={handleButtonClick}
            startIcon={<img width='17px' src={Camera} />}
            sx={{ paddingY: '10px', paddingX: '40px' }}
            variant='contained'
          >
            {uploadButtonText}
          </Button>
        </Stack>
      )}

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {enableAvatarPicker && <AvatarPicker onChange={(name: string) => (onChange ? onChange(name) : (null as any))} />}
    </>
  );
}
