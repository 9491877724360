import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import QueryBuilder from 'utils/queryBuilder';

export interface CustomAxiosInstance extends AxiosInstance {
  getDynamicData<T>(
    url: string,
    query: QueryBuilder,
    pageStart?: number,
    pageSize?: number,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IDynamicResponse<T>>>;
}

export interface IDynamicResponse<T> {
  items: T;
  index: number;
  size: number;
  count: number;
  pages: number;
  hasPrevious: boolean;
  hasNext: boolean;
}

export const DefaultDynamicResponse: any = {
  items: [],
  index: 0,
  size: 0,
  count: 0,
  pages: 0,
  hasPrevious: false,
  hasNext: false,
};
