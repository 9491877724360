import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import { PATH_AUTH, PATH_DASHBOARD } from './paths';

import {
  LoginPage,
  UserList,
  NewUser,
  Teams,
  NewTeam,
  PermissionDeniedPage,
  BlankPage,
  Page403,
  Page404,
  Page500,
  LoginActivities,
  UserProfile,
  ResetPassword,
  EditTeam,
  EditUser,
  VerifyCodePage,
  Events,
  BallotBoxList,
  NewBallotBox,
  EditBallotBox,
  Home,
  SettingsTags,
  SettingsGeneral,
  GeneralReports,
  UserReports,
  TeamReports,
} from './elements';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.resetPassword,
          element: <CompactLayout childComponent={<ResetPassword />} />,
        },
        {
          path: PATH_AUTH.verifyCode,
          element: <CompactLayout childComponent={<VerifyCodePage />} />,
        },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: PATH_DASHBOARD.userActivities, element: <LoginActivities /> },
        { path: PATH_DASHBOARD.userProfile, element: <UserProfile /> },
        { path: PATH_DASHBOARD.home, element: <Home /> },
        { path: PATH_DASHBOARD.events, element: <Events /> },
        {
          path: PATH_DASHBOARD.user.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.user.userList} replace />,
              index: true,
            },
            { path: PATH_DASHBOARD.user.userList, element: <UserList /> },
            { path: PATH_DASHBOARD.user.newUser, element: <NewUser /> },
            {
              path: PATH_DASHBOARD.user.editUser,
              element: <EditUser />,
            },
            { path: PATH_DASHBOARD.user.teams, element: <Teams /> },
            {
              path: PATH_DASHBOARD.user.editTeam,
              element: <EditTeam />,
            },
            { path: PATH_DASHBOARD.user.newTeam, element: <NewTeam /> },
          ],
        },
        {
          path: PATH_DASHBOARD.ballotBox.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.ballotBox.ballotBoxList} replace />,
              index: true,
            },
            {
              path: PATH_DASHBOARD.ballotBox.ballotBoxList,
              element: <BallotBoxList />,
            },
            {
              path: PATH_DASHBOARD.ballotBox.newBallotBox,
              element: <NewBallotBox />,
            },
            {
              path: PATH_DASHBOARD.ballotBox.editBallotBox,
              element: <EditBallotBox />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.reports.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.reports.generalReports} replace />,
              index: true,
            },
            {
              path: PATH_DASHBOARD.reports.generalReports,
              element: <GeneralReports />,
            },
            {
              path: PATH_DASHBOARD.reports.userReports,
              element: <UserReports />,
            },
            {
              path: PATH_DASHBOARD.reports.teamReports,
              element: <TeamReports />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.setting.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.setting.general} replace />,
              index: true,
            },
            { path: PATH_DASHBOARD.setting.general, element: <SettingsGeneral /> },
            { path: PATH_DASHBOARD.setting.tags, element: <SettingsTags /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDeniedPage /> },
        { path: 'blank', element: <BlankPage /> },
      ],
    },
    {
      path: '',
      element: <Navigate to={PATH_AUTH.root} replace />,
    },
    {
      element: <CompactLayout />,
      children: [{ path: '403', element: <Page403 /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '500', element: <Page500 /> }],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
