import { useState } from "react";
// @mui
import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
// locales
import { useLocales } from "../../../locales";
// components
import Image from "../../../components/image";
import MenuPopover from "../../../components/menu-popover";
import { IconButtonAnimate } from "../../../components/animate";
import ArrowDown from "assets/help_desk/arrowDown.svg";
// ----------------------------------------------------------------------

export default function LanguagePopover({ forLogin }: { forLogin?: boolean }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang: string) => {
    onChangeLang(newLang);
    handleClosePopover();
  };

  return (
    <>
      {forLogin ? (
        <MenuItem
          onClick={handleOpenPopover}
          sx={{
            borderRadius: "8px",
            display: "flex",
            gap: 2,
            backgroundColor: "#ecf1f7",
            paddingY: "10px",
            paddingX: "1.4rem",
            color: "black",
          }}
        >
          <Image
            disabledEffect
            src={currentLang.icon}
            alt={currentLang.label}
          />
          <Typography variant="body1">{currentLang.label}</Typography>
          <Image disabledEffect src={ArrowDown} alt={"Arrow"} />
        </MenuItem>
      ) : (
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            width: 40,
            height: 40,
            ...(openPopover && {
              bgcolor: "action.selected",
            }),
          }}
        >
          <Image
            disabledEffect
            src={currentLang.icon}
            alt={currentLang.label}
          />
        </IconButtonAnimate>
      )}

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 180 }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ width: 28, mr: 2 }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
