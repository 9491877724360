import { Button, Dialog, DialogContent, DialogTitle, Fab, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { m } from 'framer-motion';

// ----------------------------------------------------------------------
import { FileViewProps } from './types';
import Iconify from 'components/iconify';
import { fileFormat } from './utils';
import { SeverErrorIllustration } from 'assets/illustrations';
import { MotionContainer, varBounce } from 'components/animate';

export default function FileView({
  fileName,
  title = 'Dosya Görüntüle',
  source = 'api',
  closeButtonText = 'Kapat',
  onClose,
  ...other
}: FileViewProps) {
  const [open, setOpen] = useState<boolean>(false);
  let formattedFileName = fileName;
  let format = fileFormat(fileName);
  if (source == 'api') {
    formattedFileName = `${process.env.REACT_APP_HOST_API_KEY}/api/files/${fileName}`;
  }

  useEffect(() => {
    if (fileName) {
      setOpen(true);
    }
  }, [fileName]);

  const close = () => {
    onClose && onClose();
    setOpen(false);
  };

  switch (format) {
    case 'image':
      return (
        <Dialog maxWidth='md' open={open} onClose={close} {...other}>
          <DialogContent sx={{ m: 0, p: 0, textAlign: 'center' }}>
            <Fab color='default' size='small' sx={{ position: 'absolute', right: 5, top: 5 }} onClick={close}>
              <Iconify icon='eva:close-fill' width={24} />
            </Fab>
            <img src={formattedFileName} />
          </DialogContent>
        </Dialog>
      );
    case 'pdf':
      return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={close} {...other}>
          <DialogTitle sx={{ display: 'flex', p: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{title}</span>
            <IconButton onClick={close}>
              <Iconify icon='eva:close-fill' width={32} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ height: '640px', m: 0, p: 0 }}>
            <embed src={formattedFileName} width={'100%'} height={'630px'} />
          </DialogContent>
        </Dialog>
      );
    case 'video':
      return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={close} {...other}>
          <DialogTitle sx={{ display: 'flex', p: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{title}</span>
            <IconButton onClick={close}>
              <Iconify icon='eva:close-fill' width={32} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ height: '530px', m: 0, p: 0 }}>
            <video width={'100%'} height={'520px'} controls>
              <source src={formattedFileName} />
              Your browser does not support the video tag.
            </video>
          </DialogContent>
        </Dialog>
      );
    default:
      return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={close} {...other}>
          <DialogTitle sx={{ display: 'flex', p: 1.5, justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{title}</span>
            <IconButton onClick={close}>
              <Iconify icon='eva:close-fill' width={32} />
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{ height: '550px', m: 0, p: 0 }}>
            <MotionContainer sx={{ textAlign: 'center' }}>
              <m.div variants={varBounce().in}>
                <Typography variant='h3' paragraph>
                  Desteklenmeyen Dosya Formatı
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <Typography sx={{ color: 'text.secondary' }}>
                  Üzgünüz! Bu dosya formatı tarayıcınızda oynatmak için desteklenmemektedir.
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Lütfen aşağıdan indirerek bilgisayarınızda açmaya çalışabilirsiniz.
                </Typography>
              </m.div>

              <m.div variants={varBounce().in}>
                <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 6 } }} />
              </m.div>

              <Button
                component={RouterLink}
                to={formattedFileName}
                download
                size='large'
                variant='contained'
                startIcon={<Iconify icon='ic:baseline-cloud-download' />}
              >
                İndir
              </Button>
            </MotionContainer>
          </DialogContent>
        </Dialog>
      );
  }
}
