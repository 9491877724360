import { TextField, debounce } from '@mui/material';
import { CustomTextFieldProps } from './types';
import { useRef } from 'react';

export default function CustomTextField({ onDebounceChange, onChange, delay = 300, ...props }: CustomTextFieldProps) {
  const handleDebounceChange = useRef(
    debounce(value => {
      if (onDebounceChange) {
        onDebounceChange(value);
      }
    }, delay)
  );

  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
    handleDebounceChange.current(event);
  };

  return <TextField {...props} onChange={handleChange} />;
}
