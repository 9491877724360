// @mui
import { CircularProgress, IconButton, InputAdornment, ListSubheader, MenuItem, TextField } from '@mui/material';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';

import { DefaultDynamicResponse, IDynamicResponse } from 'types/dynamic-data';
import axiosInstance from 'utils/axios';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { CustomTextField } from 'components/custom-text-field';
import EmptyContent from 'components/empty-content/EmptyContent';
import React from 'react';
import QueryBuilder, { QuerySort } from 'utils/queryBuilder';
import { RemoteSelectProps } from './types';

// ----------------------------------------------------------------------

let currentPage: number = 0;

const RemoteSelect = forwardRef<HTMLDivElement, RemoteSelectProps>(
  (
    {
      url,
      key = 'id',
      enableSearch = true,
      searchPlaceholder,
      filterKeys = 'name',
      filter = [],
      operator = 'contains',
      logic = 'or',
      orderBy = 'id',
      order = 'desc',
      paging = true,
      debounceDelay = 500,
      menuHeight = 350,
      pageSize = 8,
      native,
      maxHeight,
      value,
      defaultValue = 0,
      error = false,
      helperText,
      children,
      placeholder = 'Seç...',
      onChange,
      renderOption = opt => opt,
      ...other
    },
    ref
  ) => {
    const [selectedOption, setSelectedOption] = useState<any>(value || defaultValue);

    if (value != selectedOption && value != null && value != defaultValue) {
      setSelectedOption(value);
    } else if (value == null && selectedOption != defaultValue) {
      setSelectedOption(defaultValue);
    }

    const [filterText, setFilterText] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<IDynamicResponse<any[]>>(DefaultDynamicResponse);

    const prepareQuery = (filterValue: string | null) => {
      const query = new QueryBuilder();

      if (Array.isArray(orderBy)) {
        (orderBy as QuerySort[]).forEach(a => query.sortNative(a));
      } else {
        query.sort(orderBy, order);
      }

      if (filter?.length) {
        filter.forEach(a => (a.logic == 'and' ? query.andNative(a) : query.orNative(a)));
      }

      if (!filterValue) {
        return query;
      }

      if (Array.isArray(filterKeys)) {
        filterKeys.forEach(key => (logic == 'and' ? query.and(key, operator, filterValue) : query.or(key, operator, filterValue)));
      } else {
        logic == 'and' ? query.and(filterKeys, operator, filterValue) : query.or(filterKeys, operator, filterValue);
      }

      return query;
    };

    const loadData = async () => {
      setLoading(true);
      return await axiosInstance.getDynamicData<any[]>(url, prepareQuery(filterText), currentPage, pageSize);
    };

    useEffect(() => {
      if (selectedOption && !options.items.some(x => keyParser(x) == selectedOption)) {
        axiosInstance
          .getDynamicData<any[]>(
            url,
            new QueryBuilder(undefined, {
              field: key,
              operator: 'eq',
              value: selectedOption?.toString(),
            }),
            0,
            1
          )
          .then(res => {
            setOptions(res.data);
          });
      } else if (!selectedOption) {
        setOptions(DefaultDynamicResponse);
      }
    }, [selectedOption]);

    useEffect(() => {
      if (filterText === null) {
        return;
      }

      currentPage = 0;
      loadData().then(res => {
        if (selectedOption && !res.data.items.some(a => keyParser(a) == selectedOption)) {
          res.data.items.push(options.items.find(a => keyParser(a) == selectedOption));
          setOptions(res.data);
        } else {
          setOptions(res.data);
        }
        setLoading(false);
      });
    }, [filterText]);

    const loadMore = () => {
      if (currentPage < 0) {
        return;
      }

      currentPage++;
      loadData().then(res => {
        const items = options.items.concat(res.data.items.filter(a => !options.items.some(b => keyParser(b) == keyParser(a))));
        res.data.items = items;
        setOptions(res.data);
        if (!res.data.hasNext) {
          currentPage = -1;
        }
        setLoading(false);
      });
    };

    const loadOnOpen = () => {
      currentPage = 0;
      loadData().then(res => {
        if (selectedOption && !res.data.items.some(a => keyParser(a) == selectedOption)) {
          res.data.items.push(options.items.find(a => keyParser(a) == selectedOption));
          setOptions(res.data);
        } else {
          setOptions(res.data);
        }
        setLoading(false);
      });
    };

    const handleClearClick = () => {
      setSelectedOption(defaultValue);
      if (onChange) {
        onChange({ target: { value: null as any } as any } as any);
      }
    };

    const handleChange = (event: ChangeEvent<any>) => {
      if (onChange) {
        onChange(event);
      }

      setSelectedOption(event.target.value);
    };

    const onScroll = (event: any) => {
      if (event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight) {
        loadMore();
      }
    };

    const keyParser = (opt: any) => (key ? opt[key] : opt);

    return (
      <TextField
        ref={ref}
        select
        fullWidth
        value={options.count ? selectedOption : defaultValue}
        onChange={handleChange}
        SelectProps={{
          native,
          MenuProps: {
            autoFocus: false,
            PaperProps: {
              onScroll: onScroll,
            },
            style: { maxHeight: menuHeight },
          },
          onClose: () => setFilterText(null),
          onOpen: () => loadOnOpen(),
          endAdornment: (
            <IconButton sx={{ visibility: selectedOption ? 'visible' : 'hidden' }} onClick={handleClearClick}>
              <ClearIcon />
            </IconButton>
          ),
        }}
        error={error}
        helperText={helperText}
        {...other}
      >
        {enableSearch && filterKeys?.length && (
          <ListSubheader>
            <CustomTextField
              delay={debounceDelay}
              size='small'
              sx={{ my: 1 }}
              autoFocus
              placeholder={searchPlaceholder}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: <React.Fragment>{loading ? <CircularProgress color='inherit' size={20} /> : null}</React.Fragment>,
              }}
              onDebounceChange={e => setFilterText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== 'Escape') {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        <MenuItem
          key={-3}
          value={-3}
          disabled
          sx={{
            display: loading ? 'flex' : 'none',
            height: '250px',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color='inherit' size={50} sx={{ textAlign: 'center', m: 4 }} />
        </MenuItem>
        <MenuItem key={-1} value={defaultValue as any} disabled sx={{ display: 'none' }}>
          {placeholder}
        </MenuItem>
        {options.items.map(option => (
          <MenuItem
            key={keyParser(option)}
            value={keyParser(option)}
            sx={{
              display: keyParser(option) == selectedOption ? 'none' : 'block',
            }}
          >
            {renderOption(option)}
          </MenuItem>
        ))}
        <MenuItem
          key={-2}
          value={-2}
          disabled
          sx={{
            display: options.items.some(item => keyParser(item) != selectedOption) ? 'none' : 'block',
          }}
        >
          <EmptyContent sx={{ p: 2 }} sxImage={{ height: 120, mb: 2 }} />
        </MenuItem>
        {children}
      </TextField>
    );
  }
);

export default RemoteSelect;
