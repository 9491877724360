import { useState } from 'react';
import { Button } from '@mui/material';
import Plus from 'assets/help_desk/home/plus-black.svg';
import CreateEventDialog from '../create-event/CreateEventDialog';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';

const HeaderButton = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Button
        sx={{ backgroundColor: '#DFE3E8', color: 'black' }}
        onClick={() => setOpenModal(!openModal)}
        startIcon={<img src={Plus} />}
        variant='contained'
      >
        Yeni Etkinlik
      </Button>
      <CreateEventDialog
        open={openModal}
        onClosed={() => {
          setOpenModal(!openModal);
          navigate(PATH_DASHBOARD.events);
        }}
      />
    </>
  );
};

export default HeaderButton;
