// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyCode: path(ROOTS_AUTH, '/verify-code/:token'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  userActivities: path(ROOTS_DASHBOARD, '/user-activities'),
  userProfile: path(ROOTS_DASHBOARD, '/user-profile'),
  events: path(ROOTS_DASHBOARD, '/events'),
  user: {
    edit: (id: number) => path(ROOTS_DASHBOARD, `/user/edit-user/${id}`),
    root: path(ROOTS_DASHBOARD, '/user'),
    userList: path(ROOTS_DASHBOARD, '/user/user-list'),
    editUser: path(ROOTS_DASHBOARD, `/user/edit-user/:id`),
    newUser: path(ROOTS_DASHBOARD, '/user/new-user'),
    editTeam: path(ROOTS_DASHBOARD, `/user/edit-team/:id`),
    editTeamFunc: (id: number) => path(ROOTS_DASHBOARD, `/user/edit-team/${id}`),
    teams: path(ROOTS_DASHBOARD, '/user/teams'),
    newTeam: path(ROOTS_DASHBOARD, '/user/new-team'),
  },
  ballotBox: {
    root: path(ROOTS_DASHBOARD, '/ballot-box'),
    ballotBoxList: path(ROOTS_DASHBOARD, '/ballot-box/ballot-box-list'),
    newBallotBox: path(ROOTS_DASHBOARD, '/ballot-box/new-ballot-box'),
    editBallotBox: path(ROOTS_DASHBOARD, '/ballot-box/edit-ballot-box/:id'),
    editBallotBoxFunc: (id: number) => path(ROOTS_DASHBOARD, `/ballot-box/edit-ballot-box/${id}`),
  },
  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    generalReports: path(ROOTS_DASHBOARD, '/reports/general-reports'),
    userReports: path(ROOTS_DASHBOARD, '/reports/user-reports'),
    teamReports: path(ROOTS_DASHBOARD, '/reports/team-reports'),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    general: path(ROOTS_DASHBOARD, '/settings/general'),
    tags: path(ROOTS_DASHBOARD, '/settings/tags'),
    security: path(ROOTS_DASHBOARD, '/settings/security'),
    notification: path(ROOTS_DASHBOARD, '/settings/notification'),
  },
};
