// form
import { Controller } from 'react-hook-form';
import { RemoteSelect } from 'components/remote-select';
import { RemoteSelectProps } from 'components/remote-select/types';
// @mui

// ----------------------------------------------------------------------

type RHFRemoteSelectProps = RemoteSelectProps & {
  name: string;
};

export default function RHFRemoteSelect({ name, helperText, ...other }: RHFRemoteSelectProps) {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => (
        <RemoteSelect {...field} select fullWidth error={!!error} helperText={error ? error?.message : helperText} {...other} />
      )}
    />
  );
}
