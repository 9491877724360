import React, { createContext, useContext, useState } from 'react';

interface TitleContextType {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  change: number;
  onChanged: React.Dispatch<React.SetStateAction<number>>;
}

export const EventContext = createContext<TitleContextType | undefined>(undefined);

type EventProviderProps = {
  children: React.ReactNode;
};
export const EventProvider = ({ children }: EventProviderProps) => {
  const [title, setTitle] = useState<string>('Tüm Etkinlikler');
  const [change, onChanged] = useState<number>(0);

  return <EventContext.Provider value={{ title, setTitle, change, onChanged }}>{children}</EventContext.Provider>;
};

export const useEventContext = () => {
  const context = useContext(EventContext);

  if (!context) throw new Error('useEventContext must be use inside EventProvider');

  return context;
};
