// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const DOCUMENT_URL_PATH = '/api/files/';
export const HOST_DOCUMENT_KEY = (fileName: string) => `${HOST_API_KEY}${DOCUMENT_URL_PATH}${fileName}`;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.home;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_LARGE: 320,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// TOOLS
// ----------------------------------------------------------------------

export const DAYJS = {
  FORMAT_DATE: 'L',
  FORMAT_DATE_TIME: 'L LT',
};

export const GOOGLE_MAPS = {
  KEY: 'AIzaSyBSJMadL4sObCeiZl8A7OlQlcxQB9w-I2M',
};
