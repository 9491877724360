import { useCallback, useState } from 'react';
// utils
import { FileUploadReturnProps } from '../types';
import uuidv4 from 'utils/uuidv4';
import axiosInstance from 'utils/axios';

// ----------------------------------------------------------------------
// 100KB
export default function useFileUpload(onCompleted?: (values: string[]) => void, chunkSize: number = 100000): FileUploadReturnProps {
  const [started, setStarted] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const onStopped = useCallback(() => {
    setProgress(0);
    setStarted(false);
  }, [started]);

  const completed = (value: string[]) => {
    onCompleted && onCompleted(value);
    stop();
  };

  const stop = () =>
    setTimeout(() => {
      onStopped();
    }, 256);

  const startUpload = async (files: File[]) => {
    setStarted(true);
    const totalFileSize = files.map(a => a.size).reduce((a, b) => a + b, 0);
    const totalChunkCount = Math.ceil(totalFileSize / chunkSize);
    const chunkPerProgress = 100 / totalChunkCount;

    let results: string[] = [];
    files.forEach(async (file: File, index) => {
      const fileChunkSize: number = Math.ceil(file.size / chunkSize);
      const fileGuid: string = uuidv4();

      for await (const i of Array.from(Array(fileChunkSize).keys())) {
        const start: number = i * chunkSize;
        const end: number = Math.min(start + chunkSize, file.size);
        const chunk: Blob = file.slice(start, end);

        const metadata = {
          fileGuid: fileGuid,
          fileName: file.name,
          index: i,
          totalCount: fileChunkSize,
          fileSize: file.size,
          fileType: file.type,
        };

        const formData: FormData = new FormData();
        formData.append('file', chunk);
        formData.append('chunkMetadata', JSON.stringify(metadata));

        const res = await axiosInstance.post('/api/files/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        setProgress(currentValue => currentValue + chunkPerProgress);
        if (res.status === 200) {
          results = [...results, res?.data?.name];

          if (results.length == files.length) {
            completed(results);
          }
        }

        if (res.status >= 400) {
          stop();
          console.log(res.data);
        }
      }
    });
  };

  return { progress, started, startUpload, onStopped, onCompleted };
}
