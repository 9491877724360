import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, IconButton, Typography, StackProps, LinearProgress } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets
import { UploadIllustration } from '../../assets/illustrations';
//
import Iconify from '../iconify';
//
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
import useFileUpload from './hooks/useFileUpload';

// ----------------------------------------------------------------------

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

// ----------------------------------------------------------------------

export default function Upload({
  disabled,
  multiple = false,
  error,
  helperText,
  exprFileName,
  file,
  onDelete,
  files = [],
  thumbnail,
  onChange,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  ...other
}: UploadProps) {
  const completed = (values: string[]) => {
    if (multiple) {
      onChange && onChange([...files, ...values]);
      onUpload && onUpload(values);
    }

    if (!multiple) {
      onChange && onChange(values[0]);
      onUpload && onUpload(values[0]);
    }
  };

  const { started, progress, startUpload } = useFileUpload(completed);
  const remoteDrop = async (files: File[]) => startUpload(files);
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    onDrop: remoteDrop,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      {hasFiles && (
        <>
          <Box sx={{ my: 3 }}>
            <MultiFilePreview exprFileName={exprFileName} files={files} thumbnail={thumbnail} onRemove={onRemove} />
          </Box>

          <Stack direction='row' justifyContent='flex-end' spacing={1.5}>
            {onRemoveAll && (
              <Button color='inherit' variant='outlined' size='small' onClick={onRemoveAll}>
                Tümü sil
              </Button>
            )}
          </Stack>
        </>
      )}

      <StyledDropZone
        {...getRootProps()}
        sx={{
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(isError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasFile && {
            padding: '12% 0',
          }),
        }}
      >
        <input {...getInputProps()} />

        <Stack
          spacing={5}
          alignItems='center'
          justifyContent='center'
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{
            opacity: hasFile ? 0 : 1,
            width: 1,
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <UploadIllustration sx={{ width: 220 }} />
          <div>
            <Typography gutterBottom variant='h5'>
              {started ? 'Dosyalar yükleniyor...' : 'Dosyayı buraya sürükle bırak veya seç'}
            </Typography>

            {started ? (
              <LinearProgress key='upload-progress' color='primary' value={progress} variant='determinate' sx={{ my: 2, width: 1 }} />
            ) : (
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                Dosyayı buraya sürükle bırak veya
                <Typography
                  variant='body2'
                  component='span'
                  sx={{
                    mx: 0.5,
                    color: 'primary.main',
                    textDecoration: 'underline',
                  }}
                >
                  buraya
                </Typography>
                tıkla
              </Typography>
            )}
          </div>
        </Stack>

        {hasFile && <SingleFilePreview file={exprFileName ? file[exprFileName] : file} />}
      </StyledDropZone>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} />

      {hasFile && onDelete && (
        <IconButton
          size='small'
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: theme => alpha(theme.palette.common.white, 0.8),
            bgcolor: theme => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: theme => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon='eva:close-fill' width={18} />
        </IconButton>
      )}
    </Box>
  );
}
