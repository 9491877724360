import Man1 from 'assets/images/avatars/man-1.png';
import Man3 from 'assets/images/avatars/man-2.png';
import Man4 from 'assets/images/avatars/man-3.png';
import Man5 from 'assets/images/avatars/man-4.png';
import Man2 from 'assets/images/avatars/man-5.png';
import Man6 from 'assets/images/avatars/man-6.png';
import Man7 from 'assets/images/avatars/man-7.png';
import Woman8 from 'assets/images/avatars/woman-1.png';
import Woman9 from 'assets/images/avatars/woman-2.png';
import Woman10 from 'assets/images/avatars/woman-3.png';
import Woman11 from 'assets/images/avatars/woman-4.png';
import Woman12 from 'assets/images/avatars/woman-5.png';
import Woman13 from 'assets/images/avatars/woman-6.png';
import Unisex1 from 'assets/images/avatars/unisex-1.png';
import Unisex2 from 'assets/images/avatars/unisex-2.png';
import Unisex3 from 'assets/images/avatars/unisex-3.png';

export const allAvatars = [
  { name: 'man-1.png', src: Man1 },
  { name: 'man-2.png', src: Man2 },
  { name: 'man-3.png', src: Man3 },
  { name: 'man-4.png', src: Man4 },
  { name: 'man-5.png', src: Man5 },
  { name: 'man-6.png', src: Man6 },
  { name: 'man-7.png', src: Man7 },
  { name: 'woman-1.png', src: Woman8 },
  { name: 'woman-2.png', src: Woman9 },
  { name: 'woman-3.png', src: Woman10 },
  { name: 'woman-4.png', src: Woman11 },
  { name: 'woman-5.png', src: Woman12 },
  { name: 'woman-6.png', src: Woman13 },
  { name: 'unisex-1.png', src: Unisex1 },
  { name: 'unisex-2.png', src: Unisex2 },
  { name: 'unisex-3.png', src: Unisex3 },
];
