// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';
import Iconify from 'components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} />;

const ICONS = {
  event: icon('ic_event'),
  user: icon('ic_user'),
  knowledgebase: icon('ic_knowledgebase'),
  template: icon('ic_template'),
  report: icon('ic_report'),
  home: <Iconify icon={'heroicons:home-solid'} width={24} />,
  ballotBox: <Iconify icon={'game-icons:vote'} width={24} />,
  cog: icon('ic_cog'),
};

const navConfig = [
  {
    subheader: 'home',
    items: [
      {
        title: 'navigation.home',
        path: PATH_DASHBOARD.home,
        icon: ICONS.home,
      },
    ],
  },
  {
    subheader: 'events',
    items: [
      {
        title: 'navigation.events',
        path: PATH_DASHBOARD.events,
        icon: ICONS.event,
      },
    ],
  },
  {
    subheader: 'user',
    items: [
      {
        title: 'navigation.user.root',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          {
            title: 'navigation.user.userList',
            path: PATH_DASHBOARD.user.userList,
          },
          {
            title: 'navigation.user.newUser',
            path: PATH_DASHBOARD.user.newUser,
          },
          { title: 'navigation.user.teams', path: PATH_DASHBOARD.user.teams },
          {
            title: 'navigation.user.newTeam',
            path: PATH_DASHBOARD.user.newTeam,
          },
        ],
      },
    ],
  },
  {
    subheader: 'ballotBox',
    items: [
      {
        title: 'navigation.ballotBox.root',
        path: PATH_DASHBOARD.ballotBox.ballotBoxList,
        icon: ICONS.ballotBox,
        children: [
          {
            title: 'navigation.ballotBox.ballotBoxList',
            path: PATH_DASHBOARD.ballotBox.ballotBoxList,
          },
          {
            title: 'navigation.ballotBox.newBallotBox',
            path: PATH_DASHBOARD.ballotBox.newBallotBox,
          },
        ],
      },
    ],
  },
  {
    subheader: 'reports',
    items: [
      {
        title: 'navigation.reports.root',
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.report,
        children: [
          {
            title: 'navigation.reports.generalReports',
            path: PATH_DASHBOARD.reports.generalReports,
          },
          {
            title: 'navigation.reports.userReports',
            path: PATH_DASHBOARD.reports.userReports,
          },
          {
            title: 'navigation.reports.teamReports',
            path: PATH_DASHBOARD.reports.teamReports,
          },
        ],
      },
    ],
  },
  {
    subheader: 'settings',
    items: [
      {
        title: 'navigation.setting.root',
        path: PATH_DASHBOARD.setting.root,
        icon: ICONS.cog,
      },
    ],
  },
];

export default navConfig;
